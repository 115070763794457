/*
Team style
*/
.team-card{
    position:relative;
    overflow:hidden;
    h4{
        color:#fff;
    }
    .team-overlay{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        position:absolute;
        width:100%;
        height:100%;
        background-color:rgba(#000,.8);
        left:0;
        top:0;
        opacity:0;
        -webkit-transition:all 350ms;
        -moz-transition:all 350ms;
        -o-transition:all 350ms;
        transition:all 350ms;
        .team-detail{
            -webkit-transform: scale(0.9);
            -moz-transform: scale(0.9);
            -o-transform: scale(0.9);
            -ms-transform: scale(0.9);
            transform: scale(0.9);
            -webkit-transition:all 250ms;
            -moz-transition:all 250ms;
            -o-transition:all 250ms;
            transition:all 250ms;
            text-align:center;
            width:100%;
            h4{
                color:#fff;
                text-transform: uppercase;
                margin-bottom:0px;
            }
            span{
                color:rgba(#fff,.8);
                display:block;
                font-size:13px;
                font-style:italic;
            }
            .seprator-overlay{
                width:80px;
                height:1px;
                margin:10px auto;
                background:rgba(#fff,.8);
            }
            ul{
                li{
                    a{
                        color:#fff;
                        &:hover{
                            color:$primary-color;
                        }
                    }
                }
            }
        }
    }
    &:hover{
        .team-overlay{
            opacity:1;
            .team-detail{
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -o-transform: scale(1);
                -ms-transform: scale(1);
                transform: scale(1);
                -webkit-transition:all 250ms;
                -moz-transition:all 250ms;
                -o-transition:all 250ms;
                transition:all 250ms;
            }
        }
    }
}

.team-card-default{
    ul li {
        &+li{
            margin-left:5px;
        }
        a{
            color: #999;
            font-size: 20px;
            &:hover{
                color: $primary-color;
            }
        }
    }
}