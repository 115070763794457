/* catamaran-100 - latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 100;
    src: url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-100.eot'); /* IE9 Compat Modes */
    src: local('Catamaran Thin'), local('Catamaran-Thin'),
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-100.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-100.svg#Catamaran') format('svg'); /* Legacy iOS */
  }
  /* catamaran-regular - latin */
  @font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Catamaran Regular'), local('Catamaran-Regular'),
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-regular.svg#Catamaran') format('svg'); /* Legacy iOS */
  }
  /* catamaran-700 - latin */
  @font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Catamaran Bold'), local('Catamaran-Bold'),
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-700.svg#Catamaran') format('svg'); /* Legacy iOS */
  }

   /* catamaran-500 - latin */
   @font-face {
     font-family: 'Catamaran';
     font-style: normal;
     font-weight: 500;
     src: url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-500.eot'); /* IE9 Compat Modes */
     src: local('Catamaran SemiBold'), local('Catamaran-SemiBold'),
          url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
          url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-500.woff') format('woff'), /* Modern Browsers */
          url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../../fonts/catamaran-v7-latin/catamaran-v7-latin-500.svg#Catamaran') format('svg'); /* Legacy iOS */
   }