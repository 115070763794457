// FontAwesome 5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

@import"fonts.scss";


$title-font:  "Catamaran", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$styled-font: "Catamaran", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$base-font:   "Catamaran", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$primary: blue;
$secondary: red;

// Bootstrap
@import '~bootstrap/scss/bootstrap';

/*
A.... CLassic Template
Version- 3.8
*/


@import"scss-classic-main/_variables.scss";

$primary-color: #7a7a7a;
$secondary-color: #000000;
$grey-color: #575656;
$secondary-light-color:#f2f2f1;

@import"scss-classic-main/_base.scss";
@import"scss-classic-main/_preloader.scss";
@import"scss-classic-main/_buttons.scss";
@import"scss-classic-main/_header.scss";
// @import"scss-classic-main/_dropdown.scss";
// @import"scss-classic-main/_alerts-notifications.scss";
// @import"scss-classic-main/_accordions.scss";
// @import"scss-classic-main/_accounts.scss";
// @import"scss-classic-main/_blockquotes.scss";
// @import"scss-classic-main/_breadcrumb.scss";
// @import"scss-classic-main/_blog.scss";
// @import"scss-classic-main/_carousel.scss";
// @import"scss-classic-main/_clients.scss";
// @import"scss-classic-main/_counter-countdown.scss";
// @import"scss-classic-main/_hero-templates.scss";
// @import"scss-classic-main/_icon-boxes.scss";
// @import"scss-classic-main/_img-boxes.scss";
// @import"scss-classic-main/_modals.scss";
// @import"scss-classic-main/_page-titles.scss";
// @import"scss-classic-main/_paginations.scss";
// @import"scss-classic-main/_portfolio.scss";
// @import"scss-classic-main/_pricing-boxes.scss";
// @import"scss-classic-main/_progress-bar.scss";
// @import"scss-classic-main/_promo-boxes.scss";
// @import"scss-classic-main/_revolution-slider.scss";
@import"scss-classic-main/_sidebar.scss";
// @import"scss-classic-main/_tabs.scss";
@import"scss-classic-main/_team.scss";
// @import"scss-classic-main/_testimonials.scss";
// @import"scss-classic-main/_title-headings.scss";
@import"scss-classic-main/_cards.scss";
// @import"scss-classic-main/_top-bars.scss";
// @import"scss-classic-main/_career.scss";
// @import"scss-classic-main/_timeline.scss";
// @import"scss-classic-main/_smart-forms.scss";
// @import"scss-classic-main/_typed.js.scss";
// @import"scss-classic-main/_image-text-card.scss";
@import"scss-classic-main/_footer.scss";
@import"scss-classic-main/_responsive-utilities.scss";
@import"scss-classic-main/_responsive.scss";

$menu_width: 280px;
@import"../../plugins/pushy/scss/pushy.scss";

@import"../../plugins/themify-icons/css/themify-icons.css";

@import"_custom.scss";
