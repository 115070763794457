/*
Template sidebar
*/
.sidebar-title{
    margin-bottom:1rem;
    font-size: 1.1rem;
}
.categories{
    li{
        vertical-align:middle;       
        >ul{
            padding-left:15px;
            >li>a{
                font-weight: 300;
            }
        }
        a{
            color:#999;
            position: relative;
            display:block;
            padding:5px 10px;
            border-bottom:1px solid #eee;
            &:before{
                content: "\f0da";
                font-family: 'FontAwesome';
                margin-right:5px;
            }
            &:hover{
                color:#444;
                background-color:#f5f5f5;
            }
        }

    }
    >li.active>a{
        font-weight: 600;
        color:#444;
    }
}

.media-body{
    h5{
        font-size: 15px;
        letter-spacing:0px;
        line-height:20px;
        font-weight:400;
        a{
            color: #555;
            &:hover{
                color: $primary-color;
            }
        }
    }
}