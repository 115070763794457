@media (min-width: 1400px) {
	.container {
		max-width: 1340px;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0.6rem;
		padding-left: 0.6rem;
	}
}

@media (max-width: 520px) {
	.mode-logo{
		position: relative;
		z-index: 10;
		top: 0 !important;
		width: 180px !important;
	}
}

@media (min-width: 520px) and (max-width: 1214px) {
	.mode-logo{
		position: relative;
		z-index: 10;
		top: 10% !important;
		width: 220px !important;
	}
}



body {
	background-color: #e9e9e9;
	//font-family: "Nunito", sans-serif;
}

.bg-lightblue1 {
	background: $lightblue1-color !important;
}

.bg-secondary-light {
	background: $secondary-light-color !important;
}

.bg-lightblue2 {
	background: $lightblue2-color !important;
}

.bg-lightblue3 {
	background: $lightblue3-color !important;
}

.mode-logo{
	position: relative;
	z-index: 10;
	top: -35px;
}

p {
	//font-weight: 500;
	letter-spacing: 0rem;
	line-height: 1.6rem;
	text-transform: none;
	font-size: 1.2rem;
	//font-family: "Nunito", sans-serif;
	color: $dark-color;
	hyphens: auto !important;
	-moz-hyphens: auto;
	-o-hyphens: auto;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;

}

.arrow div:first-child p{
	color: #ffffff;
	margin-bottom: 0;
}

.arrow div:last-child p{
	color:$primary-color;
	margin-bottom: 0;
}

a:hover {
	color: $secondary-color;
}

ul {
	list-style-type: none;
	padding-left: 0;
}
ul > li {
	//font-weight: 500;
	letter-spacing: 0rem;
	line-height: 1.6rem;
	text-transform: none;
	font-size: 1.0rem;
	//font-family: "Nunito", sans-serif;
	color: $grey-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-weight: 500;
	color: $dark-color;
	hyphens: auto!important;
	-moz-hyphens: auto;
	-o-hyphens: auto;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
}

div.bg-secondary {
	background: $secondary-color;
	h2{
		color: #ffffff;
	}
}

.markenlogos .bg-white {
	h2{
		color: $dark-color;
	}
}

.plaincards {
	.row-heading{
		h2{
			color: $dark-color;
			margin-bottom: 0;
		}
	}
}

.contactpersons {
	.row-heading{
		h2{
			color: #ffffff;
			margin-bottom: 0;
		}
	}
}

.contact {
	.row-heading{
		h2{
			color: #ffffff;
			margin-bottom: 0;
		}
	}
}

h3, .h3 {
	font-weight: 500;
	font-size: 1.6rem;
}

.lead{
font-size: 1.4rem;
}

.markenlogos{
	img{
		border: 4px solid $primary-color;
		padding: 10px;
	}
}

.modeteaser{
	img{
		padding: 10px;
	}
}

@media (max-width: 768px) {
	.modeteaser{
		img{
			padding: 0px;
		}
	}
}

section.textblock{
	h3, .h3{
		font-weight: 500;
		font-size: 1.9rem;
		margin-bottom: 30px;
	}
}

.owl-theme .owl-dots .owl-dot span{
    width: 8px;
    height: 8px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    background-color: transparent;
    border: 1px solid #ddd;
    margin-top: 10px;
    display:inline-block;
}
.owl-theme .owl-dots .owl-dot.active span{
    background-color: $primary-color;
    box-shadow: 0px 1px 5px rgba(#000,.4);
}

.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav.disabled+.owl-dots {
	margin-top: 10px;
  }

  .owl-theme .owl-dots .owl-dot {
	display: inline-block;
	zoom: 1;
  }

  .owl-theme .owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	margin: 5px 7px;
	background: #D6D6D6;
	display: block;
	-webkit-backface-visibility: visible;
	transition: opacity .2s ease;
	border-radius: 30px;
  }

section.testimonials{
	.testimonial .img-fluid{
	width: 50px !important;
	display: block;
	margin: 0;
	border-radius: 0% !important;
	-webkit-border-radius: 0% !important;
	}
	p{
		font-style: normal;
	}
}

.map{
	margin-top: -260px
}

@media (max-width: 768px) {
	.map{
		margin-top: -250px
	}
}

h2, .h2 {
	font-weight: 500;
	font-size: 2.3rem;
	//text-transform: uppercase;
}

section.tabs{
	#owl-carousel-tabs .owl-nav div {
		position: absolute;
		left: -3%;
		top: 30%;
		color: #ffffff !important;
		display: table;
		&.owl-next {
		  left: auto;
		  right: -3%;
		}
		i:before{
			color: #ffffff;
		}
	}

	.tab-icons{
		height: 145px;
		width: auto;
	}
	.tab-content{
		-webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
		-moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
		box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
	}
}

.col-border-top-primary {
	border-top: 20px solid $primary-color;
}

.col-border-top-secondary {
	border-top: 20px solid $secondary-color;
}

.col-border-top-lightblue1 {
	border-top: 20px solid $lightblue1-color;
}

.col-border-top-lightblue2 {
	border-top: 20px solid $lightblue2-color;
}

.col-border-top-lightblue3 {
	border-top: 20px solid $lightblue3-color;
}

.col-border-bottom-primary {
	border-bottom: 20px solid $primary-color;
}

.col-border-bottom-secondary {
	border-bottom: 20px solid $secondary-color;
}

.col-border-bottom-lightblue1 {
	border-bottom: 20px solid $lightblue1-color;
}

.col-border-bottom-lightblue2 {
	border-bottom: 20px solid $lightblue2-color;
}

.col-border-bottom-lightblue3 {
	border-bottom: 20px solid $lightblue3-color;
}

.navbar.navbar-transparent {
	top: 0px;
}

.navbar {
	hyphens: none!important;
}

.nav-border {
	border-bottom: 20px solid $lightblue1-color;
}

.navbar-nav > li > .nav-link {
	font-weight: 400;
	letter-spacing: 0em;
	text-transform: uppercase;
	font-size: 1.4rem;
	//font-family: "Nunito", sans-serif;
}

@media (max-width: 1200px) {
	.navbar-nav > li > .nav-link {
		font-size: 1.1rem;
	}
}

.nav-item a {
	color: #ffffff;
}
.nav-item a.active {
    color: $secondary-color;
}

.nav-item a:hover {
	//color: $secondary-color;
	color:white;
	text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
	text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
	text-shadow: 0px 0px 17px rgba(255, 255, 255, 1);
}

.middot {
	font-weight: 600;
	letter-spacing: 0em;
	text-transform: none;
	font-size: 1.7rem;
	//font-family: "Nunito", sans-serif;
	color: #ffffff;
}

.btn.herz {
	background: none;
	border: 2px solid $primary-color;
	//font-family: "Nunito", sans-serif;
	font-size: 0.8rem !important;
	text-transform: none;
	letter-spacing: 0;
}

a.btn {
	font-weight: 500 !important;
	line-height: 23px;
}

section.bluescards {
	p {
		color: #ffffff;
		font-size: 1.1rem;
		hyphens: auto !important;
		-moz-hyphens: auto;
		-o-hyphens: auto;
		-webkit-hyphens: auto;
		-ms-hyphens: auto;
	}
	h2 {
		color: #ffffff;
	}
	.card {
		border: none;
		border-radius: 0;
	}
	.btn.herz {
		border: 2px solid #ffffff;
		color: #ffffff;
		padding: 10px 5px !important;
	}
	a.btn {
		font-size: 1rem !important;
	}
	hr {
		border: 1px solid #fff;
		width: 50%;
		margin: 0 auto;
		margin-bottom: 30px;
	}
}

.btn.herz-mode-btn{
	display: inline-block;
	background-color: $primary-color !important;
	color: #ffffff;
	text-transform: none !important;
	letter-spacing: 0;
	font-size: 1rem !important;
}

section.plaincards {
	p {
		color: $dark-color;
		font-size: 1.2rem;
	}
	h4 {
		color: $primary-color;
	}
	h2, h3 {
		color: #ffffff;
	}
	.card {
		border: none;
		border-radius: 0;
	}
	.btn.herz {
		border: 2px solid $primary-color;
		color: $primary-color;
		padding: 10px 5px !important;
	}
	hr {
		border: 1px solid $grey-color;
		width: 50%;
		margin: 0 auto;
		margin-bottom: 10px;
	}
}

.modeteaser{
	h2{
	color: #ffffff;
	font-size: 4.5rem;
	font-weight: 500;
	}
	.owl-carousel .owl-nav div {
		position: absolute;
		left: -4%;
		top: 45%;
		color: #ffffff !important;
		display: table;
		&.owl-next {
		  left: auto;
		  right: -4%;
		}
		i:before{
			color: #ffffff;
		}
	}
	@media (max-width: 768px) {
		.owl-carousel .owl-nav div {
			position: absolute;
			left: 2%;
			top: 45%;
			&.owl-next {
			  left: auto;
			  right: 2%;
			}
		}
	}
}

.sortiment-icons{
	height: 90px;
	margin-bottom: 25px;
}

@media (max-width: 768px) {
	.sortiment-icons{
		height: 65px;
		margin-bottom: 10px;
	}
}

.sortiment h3{
	font-size: 1.2rem;
}

.team-detail{
	h2{
	color: $dark-color;
	font-size: 1.2rem;
	font-weight: 400;
	}
	hr {
		border: 1px solid $dark-color;
		width: 50%;
		margin: 10 auto;
	}
	span{
		color: $dark-color;
	}
}

.team-card .team-overlay:hover {
	background-color: $lightblue3-color;
	opacity: 0.95;
}

footer.footer .text.white p {
    color: #ffffff!important;
}


.footer {
	p {
	    color: rgba(255, 255, 255, 1);
	}
	a:active, a:visited, a:link {
		color: #ffffff;
	}
	a:hover {
		//color: $lightblue3-color;
		//color: $secondary-color;
		//text-shadow: 2px 2px #ff0000;
		text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
		text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
		text-shadow: 0px 0px 15px rgba(255, 255, 255, 1);

	}
	a.active {
	    color: $secondary-color;
	}
}

#subfooter{
	a:link {
	    color: #ffffff;
	}
	a:hover {
	    //color: $lightblue3-color;
	    //color: $secondary-color;
		//text-shadow: 2px 2px #ff0000;
		//color: $primary-color;
		color: rgb(203, 203, 203);
		text-decoration: underline;
	    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
	    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
	    text-shadow: 0px 0px 15px rgba(255, 255, 255, 1);

	}
	a.active {
	    //color: $primary-color;
		color: rgb(203, 203, 203);
		text-decoration: underline;
	}

}

footer {

	.text.white p {
		color: #ffffff;
	}
	p {
		color: #ffffff;
		margin-top: 20px;
	}

	a {
		color: #ffffff;
	}
	h2 {
		font-size: 1.3rem;
		color: #ffffff;
	}
	ul > li {
		font-size: 1rem;
		color: #ffffff;
	}
	.adresse {
		ul > li {
			font-size: 1rem;
			color: #ffffff;
			margin-bottom: 15px;
		}
		ul > li:before {
			content: none;
		}
	}
	ul > li:before {
		content: "\00BB \0020";
		font-size: 20px;
	}
	ul {
		margin-top: 20px;
		list-style: none;
	}
}

.fa-border {
	border-radius: 100px;
	width: 30px;
	height: 30px;
	border-width: 2px;
	text-align: center;
	margin-right: 15px;
	padding-top: 4px;
}

.fa-envelope:before {
	font-size: 14px;
}

.fa-mobile:before {
	font-size: 20px;
}

.fa-phone:before{
	font-size: 12px;
}

#subfooter {
	ul {
		margin-bottom: auto;
		margin-top: auto;
	}
	li {
		display: inline; /* li nebeneinander anzeigen */
		list-style-type: none; /* ohne Aufzählungspunkte */
		margin-right: 15px;
		font-size: 1rem;
		color: #ffffff;
	}
	p {
		font-size: 1rem;
	}
}


.fa-bars:before {
		font-size: 25px;
		color: #ffffff;
	}

.ti-close {
	color: #ffffff;
	text-decoration: none;
	opacity: 1;
	top: 55px !important;
	position: absolute;
	left: 40px !important;
	font-size: 25px;
}

.navbar-right-elements{
	top: 15px;
	z-index: 999999;
}

.navbar-right-elements .list-inline > li > a {
	top: 0;
}

.navbar-toggler-icon {
	width: 100%;
	height: auto;
}

.pushy {
	background-color: $primary-color;
	color: #ffffff;
	.list-inline-item {
		width: 100%;
	}
	a {
		color: #ffffff !important;
		cursor: pointer;
		//font-weight: 500;
		//font-family: "Nunito", sans-serif;
	}
}

.pushy {
	.cart-content {
		padding: 0px 40px;
		position: relative;
		h4 small {
			font-size: 13px;
			display: block;
			padding-bottom: 10px;
			color: rgba(255, 255, 255, 0.8);
		}
		.pushy-link {
			display: inline-block;
			position: absolute;
			top: 10px;
			right: 10px;
			padding: 0;
		}
		ul {
			padding-top: 30px;
			li {
				padding: 12px 0;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				a:not(.btn-primary) {
					padding: 0;
					font-size: 1rem;
					.img-fluid {
						width: 60px;
						margin-right: 15px;
					}
				}
				.close {
					text-shadow: none;
					color: #ffffff;
					font-size: 1rem;
				}
				p {
					font-size: 13px;
				}
			}
			li.clearfix {
				border-bottom: none;
			}
		}
	}
}

.team-card.visible{
	position: static;
	&:hover {
	    .team-overlay {
			opacity: 1;
			background-color: $primary-color;

	        .team-detail {

	        }
	    }
	}
}

.team-overlay.visible {
	opacity: 1;
	background-color: $primary-color;

    .team-detail {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-o-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
    }
}

.carousel-project2, .carousel-project{
	background-color: $lightblue2-color;
}

.owl-carousel.bg-secondary  {
	p, h4, span {
		color: $primary-color;
	}
}

/**project thumb**/
.project-thumb {
	text-align: center; }
	.project-thumb img {
	  width: 100% !important; }
	.project-thumb > a {
	  transition: 0.3s ease;
	  -webkit-transition: 0.3s ease;
	  -moz-transition: 0.3s ease;
	  margin-bottom: 1.85714286em;
	  display: block; }

  /**project overlay**/
  .project-overlay {
	display: block;
	position: relative;
	transition: 0.3s ease;
	-webkit-transition: 0.3s ease;
	-moz-transition: 0.3s ease;
	border-radius: 0px;
	-webkit-border-radius: 0px;
	overflow: hidden; }
	.project-overlay img {
	  width: 100% !important; }
	.project-overlay .project-overlay-caption {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  padding-left: 30px;
	  padding-top: 30px;
	  background-color: rgba(0, 0, 0, 0.5); }
	  .project-overlay .project-overlay-caption h4, .project-overlay .project-overlay-caption span {
		color: #fff; }
	  .project-overlay .project-overlay-caption h4 {
		font-weight: 600;
		margin-bottom: 5px; }



		.ti-angle-left:before, .ti-angle-right:before{
			font-size: 30px;
			color: $primary-color;
		}

		.plaincards{
			.fa{
				color: $primary-color;
				margin-bottom: 10px;
			}
			.fa::before{
				font-size: 38px;
			}
		}

		.owl-nav div {
			position: absolute;
			left: 0px;
			top: 45%;
			color: #ffffff;
			display: table;
			&.owl-next {
			  left: auto;
			  right: 0px;
			}
		  }

		.carousel-project{
			.owl-nav div {
				position: absolute;
				left: -40px;
				top: 50%;
				color: #ffffff;
				display: table;
				&.owl-next {
				  left: auto;
				  right: -40px;
				}
			  }
		}

		.show-inverted-commas::before{
			background-image: url(/images/blockquote.svg);
			position: absolute;
			top: 65px;
			background-size: 50px 57px;
			background-repeat: no-repeat;
			display: inline-block;
			width: 50px;
			height: 57px;
			content: "";
			left: 35px;
		}

		@media (max-width: 768px){
			.container{
				max-width: 100%;
			}
		}

		.triangle {
			width: 0;
			height: 0;
			border-top: 28px solid transparent;
			border-left: 20px solid $primary-color;
			border-bottom: 28px solid transparent;
			background-color: $secondary-color;
		  }

		  	#sortiment a.active img.content-icon-notactive {
			display:none;
			}
			#sortiment a.notactive img.content-icon-active {
				display:none;
			}
			#sortiment a.active img.content-icon-active {
				display:inline-block;
			}
			#sortiment a.notactive img.content-icon-notactive {
				display:inline-block;
			}
